import React, { useState } from "react";
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import logo1 from '../assets/TIG Logo1.png';
import logo2 from '../assets/techtron-logo-fotor-20240903162951.png';
import bg3 from '../assets/bgTechtron.jpg';
import clgImage from '../assets/clgBlack.png';
import schlImage from '../assets/schlBlack.png';
import hotelImage from '../assets/hotelBlack.png';
import purchaseImage from '../assets/purBlack.png';
import niveditaImage from '../assets/niveditaBlack.png';
import tiutImage from '../assets/tiutBlack.png';
import placementImage from '../assets/tpoBlack.png';
import teachingImage from '../assets/teachingBlack.png';
import hospitalImage from '../assets/hospitalBlack.png';
import mediaImage from '../assets/mediaBlack.png';
import misRptImage from '../assets/clgMis.png';
import financeImage from '../assets/financeBlack1.png';
import auditImage from '../assets/auditBlack1.png';
import hrImage from '../assets/hrBlack.png';
import misrpt from '../assets/misBlack.png';
import alumniBlack from '../assets/alumniBlack.png';
import maintainanceImage from '../assets/maintanceBlack.png';
import { Link } from 'react-router-dom';
import { ProjectOutlined } from '@ant-design/icons';
import Hotel from "./Hotel";



function LandingPage() {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };
  return (
    // <div style={styles.container}>
    //     <div style={styles.overlay}> 
    //         <div style={styles.buttonContainer}> 
    //             {buttons.map((button, index) => (
    //                 <a 
    //                     key={index} 
    //                     href={button.href} 
    //                     style={styles.link} 
    //                     target="_blank" 
    //                     rel="noopener noreferrer"
    //                 >
    //                     <button
    //                         style={{
    //                             ...styles.button,
    //                             ...(hovered === index && styles.buttonHover),
    //                         }}
    //                         onMouseEnter={() => setHovered(index)}
    //                         onMouseLeave={() => setHovered(null)}
    //                     >
    //                         <img 
    //                             src={button.imageSrc} 
    //                             alt={button.text} 
    //                             style={styles.image} 
    //                         />
    //                     </button>
    //                     <div style={styles.buttonText}>
    //                         {button.text}
    //                     </div>
    //                 </a>
    //             ))}
    //         </div>
    //     </div>
    // </div>

    <div className="App">
      <div className="logo-container">
        <img src={logo1} alt="Logo 1" style={styles.topLogo1} />
        <img src={logo2} alt="Logo 2" style={styles.topLogo2} />
      </div>
      <div className="logo-container">
        <section className="modules">
        <motion.div
            whileHover={{ scale: 1.05 }}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            onClick={() => window.location.href = 'http://www.techtron.net:4669/'}

          >
            <Link className="projects-link" >
            
            <div className=".icon-section-container ">
              <div className="icon-section-nivedita">
                <img src={niveditaImage} alt="sister" className="project-image" />
              </div>
              </div>
              <div className="project-title">
                Sister Nivedita University
              </div>
            </Link>
          </motion.div>
        <motion.div
            whileHover={{ scale: 1.05 }}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            onClick={() => window.location.href = 'http://erp.tiut.ac.in/'}

          >
            <Link to="/#" className="projects-link" >
              <div className="icon-section-tiut">
                <img src={tiutImage} alt="tiut" className="project-image" />
              </div>
              <div className="project-title">
                Techno India University Tripura
              </div>
            </Link>
          </motion.div>
        
         
          <motion.div
            whileHover={{ scale: 1.05 }}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <Link to="/collegeManagement" className="projects-link" >
              <div className="icon-section-clg">
                <img src={clgImage} alt="Sales Module" className="project-image" />
              </div>
              <div className="project-title">
                College Management
              </div>
            </Link>
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <Link to="/schoolManagement" className="projects-link" >
              <div className="icon-section-schl">
                <img src={schlImage} alt="schl" className="project-image" />
              </div>
              <div className="project-title">
                School Management
              </div>
            </Link>
          </motion.div>
          
          <motion.div
            whileHover={{ scale: 1.05 }}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <Link to="/hotelManagement" className="projects-link" >
              <div className="icon-section-hotel">
                <img src={hotelImage} alt="hotel" className="project-image" />
              </div>
              <div className="project-title">
                Hotel Management
              </div>
            </Link>
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            onClick={() => window.location.href = 'https://smch.techtron.net/'}
          >
            <Link className="projects-link" >
              <div className="icon-section-hospital">
                <img src={hospitalImage} alt="schl" className="project-image" />
              </div>
              <div className="project-title">
                Hospital Management
              </div>
            </Link>
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <Link to="/#" className="projects-link" >
              <div className="icon-section-teaching">
                <img src={mediaImage} alt="teaching" className="project-image" />
              </div>
              <div className="project-title">
                Media
              </div>
            </Link>
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <Link to="/purchaseManagement" className="projects-link" >
              <div className="icon-section-purchase">
                <img src={purchaseImage} alt="purchase" className="project-image" />
              </div>
              <div className="project-title">
                Purchase Management
              </div>
            </Link>
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            onClick={() => window.location.href = 'http://www.techtron.net:9333/'}

          >
            <Link className="projects-link" >
              <div className="icon-section-audit">
                <img src={auditImage} alt="placement" className="project-image" />
              </div>
              <div className="project-title">
              Audit & Feedback
              </div>
            </Link>
          </motion.div>

          <motion.div
            whileHover={{ scale: 1.05 }}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <Link to="/finance&review" className="projects-link" >
              <div className="icon-section-finance">
                <img src={financeImage} alt="finance" className="project-image" />
              </div>
              <div className="project-title">
              Finance & Review
              </div>
            </Link>
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            onClick={() => window.location.href = 'http://myhr.techtron.net/'}
          >
            <Link to="/#" className="projects-link" >
              <div className="icon-section-teaching">
                <img src={hrImage} alt="teaching" className="project-image" />
              </div>
              <div className="project-title">
                HRIS
              </div>
            </Link>
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            onClick={() => window.location.href = 'http://mis.techtron.net/'}
          >
            <Link to="/#" className="projects-link" >
              <div className="icon-section-mis">
                <img src={misrpt} alt="mis" className="project-image" />
              </div>
              <div className="project-title">
              M&nbsp;I&nbsp;S&nbsp;     
              </div>
            </Link>
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            onClick={() => window.location.href = 'http://www.techtron.net:6999/'}

          >
            <Link className="projects-link" >
              <div className="icon-section-teaching">
                <img src={teachingImage} alt="teaching" className="project-image" />
              </div>
              <div className="project-title">
                Teaching Management
              </div>
            </Link>
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            onClick={() => window.location.href = 'http://www.techtron.net:9599/'}
          >
            <Link className="projects-link" >
              <div className="icon-section-tpo">
                <img src={placementImage} alt="placement" className="project-image" />
              </div>
              <div className="project-title">
              Training & Placement
              </div>
            </Link>
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <Link to="/#" className="projects-link" >
              <div className="icon-section-tpo">
                <img src={alumniBlack} alt="placement" className="project-image" />
              </div>
              <div className="project-title">
              Alumni
              </div>
            </Link>
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <Link to="/#" className="projects-link" >
              <div className="icon-section-tpo">
                <img src={maintainanceImage} alt="placement" className="project-image" />
              </div>
              <div className="project-title">
             Document
              </div>
            </Link>
          </motion.div>

        </section>
      </div>
    </div>
  );
};


const styles = {

  topLogo1: {
    width: '80px',
    height: 'auto',

    animation: 'fadeInScale 2s ease-in-out',
  },
  topLogo2: {
    width: 'auto',
    height: '100px',
    animation: 'fadeInScale 2s ease-in-out',
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundImage: `url(${bg3})`,
    backgroundSize: 'cover',
    backgroundPosition: "center",

  },
  overlay: {
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: 'rgba(0,0,0,0.1)',
    width: '100%',
    height: '100%',
  },
  buttonContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '30px',
  },
  link: {
    textDecoration: 'none',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '120px',
    width: '260px',
    padding: '15px 25px',
    fontSize: '20px',
    color: '#fff',
    backgroundColor: 'rgb(242 242 242 / 85%)',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'transform 0.2s, background-color 0.3s',
  },
  buttonHover: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    transform: 'scale(1.05)',

  },
  image: {
    maxWidth: '100%',
    height: '120px',
  },
  buttonText: {
    marginTop: '2px',
    fontSize: '15px',
    color: '#fff',
    textAlign: 'center',
    fontWeight: '700'

  },

};
export default LandingPage;
