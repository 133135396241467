import React from 'react'

const MisReport = () => {
  return (
    <div>
    <h1 style={{color:"white"}}>
      MIS
    </h1>
 </div>
  )
}

export default MisReport