import React from 'react'

const Teaching = () => {
  return (
    <div>
      <h1 style={{ color: "white" }}>
        Teaching Management
      </h1>
    </div>
  )
}

export default Teaching