import React from 'react'
import niveditaImage from '../assets/snu.png';
import { motion } from 'framer-motion';
import logo1 from '../assets/TIG Logo1.png';
import logo2 from '../assets/techtron-logo-fotor-20240903162951.png';

const SnuPage = () => {
  return (
    <div className="App">
      <div className="logo-container">
        <img src={logo1} alt="Logo 1" style={styles.topLogo1} />
        <img src={logo2} alt="Logo 2" style={styles.topLogo2} />
      </div>
    </div>
  )
}

  const styles = {

    topLogo1: {
      width: '110px',
      height: '100px',
      animation: 'fadeInScale 2s ease-in-out',
    },
    topLogo2: {
      width: 'auto',
      height: '120px',
      animation: 'fadeInScale 2s ease-in-out',
    },
  }

export default SnuPage