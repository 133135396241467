import React from 'react'

const TiutPage = () => {
  return (
    <div>
       <h1 style={{color:"white"}}>
       Welcome to Techno India University of Tripura
       </h1>
    </div>
  )
}

export default TiutPage