import React from 'react';
import { useEffect, useState } from 'react';
import './App.css';
import LandingPage from './Pages/LandingPage';
import SplashScreen from './Pages/SplashScreen';

function App() {
  const [ isSplashVisible, setIsSplashVisible ] = useState(true)
  useEffect(() =>{
    const timer = setTimeout(() =>{
      setIsSplashVisible(false)
    },3000);
    return () => clearTimeout(timer);
  },[])
  return (
    <>
    {isSplashVisible? <SplashScreen /> : <LandingPage />}
    </>
  );
}

// function App() {
//   return (
//     <div className="App">
     
//       <section className="modules">
//         <motion.div
//           className="module-card"
//           whileHover={{ scale: 1.05 }}
//           initial={{ opacity: 0, x: -50 }}
//           animate={{ opacity: 1, x: 0 }}
//           transition={{ duration: 0.5, delay: 0.2 }}
//         >
//           <img src={hrImage} alt="HR Module" />
//           <h5>HR Module</h5>
//         </motion.div>

//         <motion.div
//           className="module-card"
//           whileHover={{ scale: 1.05 }}
//           initial={{ opacity: 0, x: 50 }}
//           animate={{ opacity: 1, x: 0 }}
//           transition={{ duration: 0.5, delay: 0.4 }}
//         >
//           <img src={financeImage} alt="Finance Module" />
//           <h5>Finance Module</h5>
//         </motion.div>

//         <motion.div
//           className="module-card"
//           whileHover={{ scale: 1.05 }}
//           initial={{ opacity: 0, x: -50 }}
//           animate={{ opacity: 1, x: 0 }}
//           transition={{ duration: 0.5, delay: 0.6 }}
//         >
//           <img src={inventoryImage} alt="Inventory Module" />
//           <h5>Inventory Module</h5>
//         </motion.div>

//         <motion.div
//           className="module-card"
//           whileHover={{ scale: 1.05 }}
//           initial={{ opacity: 0, x: 50 }}
//           animate={{ opacity: 1, x: 0 }}
//           transition={{ duration: 0.5, delay: 0.8 }}
//         >
//           <img src={salesImage} alt="Sales Module" />
//           <h5>Sales Module</h5>
//         </motion.div>
//       </section>
//     </div>
//   );
// }
export default App;
