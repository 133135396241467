import React from 'react';
import logo1 from '../assets/TIG Logo1.png';
import logo2 from '../assets/techtron-logo-fotor-20240903162951.png';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import purchaseImage from '../assets/purBlack.png';
import { useNavigate } from "react-router-dom";


const Purchase = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); 
  };
  return (
    <div className="clg-App">
      <div className="logo-container">
        <img src={logo1} alt="Logo 1" style={styles.topLogo1} onClick={goBack} />
        <img src={logo2} alt="Logo 2" style={styles.topLogo2} onClick={goBack} />
      </div>
      <section className="modules">
                <motion.div
                    whileHover={{ scale: 1.05 }}
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                    onClick={() => window.location.href = 'http://www.techtron.net:9444/'}

                >
                    <Link className="projects-link" >
                        <div className="icon-section-clg">
                            <img src={purchaseImage} alt="Sales Module" className="project-image" />
                        </div>
                        <div className="project-title">
                        Purchase (V.1.0)
                        </div>
                    </Link>
                </motion.div>

                <motion.div
                    whileHover={{ scale: 1.05 }}
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                    onClick={() => window.location.href = 'http://www.techtron.net:9448/'}

                >
                    <Link className="projects-link" >
                        <div className="icon-section-clg">
                            <img src={purchaseImage} alt="Sales Module" className="project-image" />
                        </div>
                        <div className="project-title">
                        Purchase (V.2.0)
                        </div>
                    </Link>
                </motion.div>
                <motion.div
                    whileHover={{ scale: 1.05 }}
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                    onClick={() => window.location.href = 'http://www.techtron.net:9446/'}
                >
                    <Link className="projects-link" >
                        <div className="icon-section-clg">
                            <img src={purchaseImage} alt="Sales Module" className="project-image" />
                        </div>
                        <div className="project-title">
                        Purchase (V.3.0)
                        </div>
                    </Link>
                </motion.div>

            </section>
    </div>
  )
}
const styles = {

  topLogo1: {
    width: '110px',
    height: '100px',
    animation: 'fadeInScale 2s ease-in-out',
  },
  topLogo2: {
    width: 'auto',
    height: '120px',
    animation: 'fadeInScale 2s ease-in-out',
  },
}

export default Purchase