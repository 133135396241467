import React from "react";
import logo1 from '../assets/TIG Logo1.png';
import logo2 from '../assets/techtron-logo-fotor-20240903162951.png';
// import bg1 from '../assets/bg1.jpg';
// import bg2 from '../assets/bg2.jpg';
import bg3 from '../assets/bgTechtron.jpg';

function SplashScreen() {
  return (
    <div style={styles.container}>
    <div style={styles.overlay}>  
        <img src={logo1} alt="Logo1" style={styles.logo1} />
        <img src={logo2} alt="Logo2" style={styles.logo2} />
    </div>   
    </div>
  );
};

const styles = {
    container:{
        display: "flex",
        justifyContent:"center",
        alignItems:"center",
        height:'100vh',
        backgroundImage: `url(${bg3})`,
        backgroundSize:'cover',
        backgroundPosition:"center",
        // position:'relative'
    },
    overlay:{
        display: "flex",
        flexDirection: 'column',
        justifyContent:"center",
        alignItems:"center",
        backgroundColor: 'rgba(0,0,0,0.5)',
        width:'100%',
        height:'100%',
    },
    logo1:{
        width:'auto',
        height:'150px',
        animation: 'fadeInScale 2s ease-in-out',
        marginBottom: '15px',
    },
    logo2:{
        width:'300px',
        height:'200px',
        animation: 'fadeInScale 2s ease-in-out',
    }
};
  const styleSheet = document.styleSheets[0];
  const keyframes = 
  `@keyframes fadeInScale{
  0%{
   opacity:0;
   transform: scale(0.5);
  }
  100%{
   opacity:1;
   transform: scale(1);
  } 
  }`;
  styleSheet.insertRule(keyframes,styleSheet.cssRules.length);
export default SplashScreen;
