import React from 'react'

const Hospital = () => {
  return (
    <div>
    <h1 style={{color:"white"}}>
    Hospital Management
    </h1>
 </div>
  )
}

export default Hospital