import React from 'react'

const AuditFeedback = () => {
  return (
    <div>
    <h1 style={{color:"white"}}>
    Audit & Feedback
    </h1>
 </div>
  )
}

export default AuditFeedback